import { Vector2 } from "three"
import { App } from "../App"
import { EventEmitter } from "../Utils/EventEmitter"
import KnifeCloseButton from "./UI3D/KnifeCloseButton"
import KnifeOpenButton from "./UI3D/KnifeOpenButton"
import Logo from "./UIScreen/Logo"
import ShoppingCart from "./UIScreen/ShoppingCart"
import Zoom from "./UIScreen/Zoom"
import FinishMenu from "./UI3D/FinishMenu"
import WeightMenu from "./UI3D/WeightMenu"
import { Utils } from "../Utils/Utils"

const SIZE_DEPENDANT = {
    position : {
        "15": {
            open: new Vector2(0.2, 1.8),
            close: new Vector2(0.35, -0.65),
            finish: new Vector2(-2.5, 0.45),
            weight: new Vector2(0.35, 0.55)
        },
        "27": {
            open: new Vector2(0.8, 1.8),
            close: new Vector2(0.35, -0.38),
            finish: new Vector2(-2.95, 1.0),
            weight: new Vector2(0.35, 1.15)
        },
        "37":  {
            open: new Vector2(0.8, 1.8),
            close: new Vector2(0.35, 0.0),
            finish: new Vector2(-2.95, 1.0),
            weight: new Vector2(0.35, 1.5)
        },
    }
}

export default class UIManager extends EventEmitter {
    constructor(container) {
        super()

        this.container = container
        this.mainScreen = document.querySelector('.ui-screen')
        this.mainWorld = document.querySelector('.ui-3d')
        
        this.app = new App()
        
        this.logo = null
        this.shoppingCart = null
        this.zoom = null
        
        this.closeButton = null
        this.openButton = null

        this.finishMenu = null
        this.weightMenu = null

        this.init()
    }
    
    init() {
        this.initScreen()
        this.initWorld()
    }
    
    initScreen() {
        this.logo = new Logo('ui-logo')
        this.shoppingCart = new ShoppingCart('ui-shopping-cart')
        this.zoom = new Zoom('ui-zoom')
    }
    
    initWorld() {
        this.closeButton = new KnifeCloseButton(this, 'ui-close', new Vector2(0, 0), false)
        this.openButton = new KnifeOpenButton(this, 'ui-open', new Vector2(0, 0), false)
        this.finishMenu = new FinishMenu(this, 'ui-finish', new Vector2(0, 0), false)
        this.weightMenu = new WeightMenu(this, 'ui-weight', new Vector2(0, 0), false)
    }

    setMenusWithWeight(weight) {
        this.closeButton.worldPt = Utils.Vector2FromVector3(SIZE_DEPENDANT.position[weight].close)
        this.openButton.worldPt = Utils.Vector2FromVector3(SIZE_DEPENDANT.position[weight].open)
        this.finishMenu.worldPt = Utils.Vector2FromVector3(SIZE_DEPENDANT.position[weight].finish)
        this.weightMenu.worldPt = Utils.Vector2FromVector3(SIZE_DEPENDANT.position[weight].weight)
    }
    
    worldToScreenPt(worldPt) {
        return Utils.worldToScreen(worldPt, this.app.camera.instance, this.app.renderSize.size)
    }
    
    closeKnife() {
        this.trigger('closeKnife')
        this.closeButton.instance.style.opacity = 0
    }
    
    openKnife() {
        this.trigger('openKnife')
        this.openButton.instance.style.opacity = 0
    }
    
    changeKnifeFinish(finish) {
        this.trigger('changeKnifeFinish', [{finish}])
    }
    
    changeKnifeWeight(weight) {
        this.trigger('changeKnifeWeight', [{weight}])
    }

    toggleMenus() {
        this.finishMenu.instance.style.opacity = 1 - this.finishMenu.instance.style.opacity
        this.weightMenu.instance.style.opacity = 1 - this.weightMenu.instance.style.opacity
    }

    showOpenCloseButtons() {
        this.closeButton.instance.style.opacity = 1
        this.openButton.instance.style.opacity = 1
    }
    
    hideOpenCloseButtons() {
        this.closeButton.instance.style.opacity = 0
        this.openButton.instance.style.opacity = 0        
    }

    toggleOpenCloseButtons(open) {
        if (open) {
            this.closeButton.instance.style.opacity = 1
        }
        else {
            this.openButton.instance.style.opacity = 1
        }
    }

    update() {
        this.closeButton.update()
        this.openButton.update()
        this.finishMenu.update()
        this.weightMenu.update()
    }
    
    destroy() {

        this.logo.destroy()
        this.logo = null

        this.shoppingCart.destroy()
        this.shoppingCart = null

        this.zoom.destroy()
        this.zoom = null

        this.logo = null
        this.logo.destroy()

        this.shoppingCart = null
        this.shoppingCart.destroy()

        this.zoom = null
        this.zoom.destroy()
        
        this.closeButton.destroy()
        this.closeButton = null
        
        this.openButton.destroy()
        this.openButton = null

        this.finishMenu.destroy()
        this.finishMenu = null

        this.weightMenu.destroy()
        this.weightMenu = null

        this.mainScreen = null
        this.mainWorld = null
        this.container = null

        this.app = null
    }
}
