export default class ShoppingCart {
    constructor(className) {
        this.element = document.querySelector('.' + className)
        
        this.init()
    }
    
    init() {
        
    }
    
    destroy() {
        this.element = null
    }
}
