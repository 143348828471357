export class Memory {
    constructor() {
    }

    static releaseObject3DMaterials(object3D) {
        object3D.traverse((node) => {
            if (node.isMesh) {
                
                const mat = node.material
                Object.entries(mat).map((prop) => {
                    if (mat[prop[0]] && typeof mat[prop[0]].dispose === "function") {
                        console.log(`Dispose ${node.name}:material.${prop[0]}`)
                        mat[prop[0]].dispose()
                    }
                })
                
                if (mat) {
                    console.log(`Dispose ${node.name}:material`)
                    mat.dispose()
                    node.material = null
                }
            }
        })
    }

    static releaseObject3D(object3D) {
        object3D.traverse((node) => {
            if (node.isMesh) {
                
                if (node.geometry) {
                    console.log(`Dispose ${node.name}:geometry`)
                    node.geometry.dispose()
                    node.geometry = null
                }
            }
        })

        Memory.releaseObject3DMaterials(object3D)
    }
}