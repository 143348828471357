import { App } from "./App"
import { EventEmitter } from "./Utils/EventEmitter"

const DEFAULT_CONFIG = {
    knife: {
        weight: "27",
        finish : "mirror"
    }
}


export default class State extends EventEmitter {
    constructor() {
        super()

        this.app = new App()
        this.knifeWeight = DEFAULT_CONFIG.knife.weight
        this.knifeFinish = DEFAULT_CONFIG.knife.finish
        this.init()
    }

    init() {
    }
    
    toJSON() {
        return {
            knife: {
                weight : this.knifeWeight,
                finish : this.knifeFinish
            }
        }
    }
    
    destroy() {

        
    }
};
