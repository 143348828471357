import { Camera } from './Core/Camera.js'
import { Renderer } from './Core/Renderer.js'
import { AnimationLoop } from './Utils/AnimationLoop.js'
import { RenderSize } from './Utils/RenderSize.js'
import { AssetManager } from './Utils/AssetManager.js'
import { Debug } from './Utils/Debug.js'

import assets from "/assets.js"

import * as THREE from 'three'
import { Memory } from './Utils/Memory.js'
import { Lights } from './World/Lights.js'
import Ground from './World/Ground.js'
import UIManager from './UI/UIManager.js'
import State from './State.js'
import Knife from './World/Knife.js'
import gsap from 'gsap'

let instance = null

export class App {
    constructor(canvas) {
        if (instance !== null) {
            return instance
        }
        instance = this

        this.canvas = canvas

        this.debug = new Debug()
        
        this.animationLoop = new AnimationLoop()
        this.animationLoop.on('update', () => {
            this.update()
        })

        // this.gamepad = new Gamepad()
        
        this.renderSize = new RenderSize()
        this.renderer = new Renderer()

        this.camera = new Camera()
        const cameraCenterAngle = Math.PI / 3
        const cameraDeltaAngle = Math.PI / 6
        this.camera.setLimits(4, 7, cameraCenterAngle, cameraCenterAngle + cameraDeltaAngle)
        this.camera.setPositionZ(6)

        this.scene = new THREE.Scene()

        this.ui = null
        
        this.state = new State()
        
        this.worldGroup = null
        this.ground = null
        this.knife = null
        
        this.closeKnifeBound = this.closeKnife.bind(this)
        this.openKnifeBound = this.openKnife.bind(this)
        this.changeKnifeFinishBound = this.changeKnifeFinish.bind(this)
        this.changeKnifeWeightBound = this.changeKnifeWeight.bind(this)
        
        this.onKnifeOpenedBound = this.onKnifeOpened.bind(this)
        this.onKnifeClosedBound = this.onKnifeClosed.bind(this)
        
        this.assetManagerReadyHandlerBound = this.initScene.bind(this)
        
        this.assetManager = new AssetManager(assets)
        this.assetManager.on('ready', this.assetManagerReadyHandlerBound)
        this.assetManager.startLoading()

        this.animationLoop.start()
        this.initUI()
    }
    
    initScene() {
        this.lights = new Lights()
        this.scene.add(this.lights.instance)

        this.scene.background = this.assetManager.items.restaurant
        this.scene.backgroundBlurriness = 0.02
        this.scene.backgroundIntensity = 0.25

        this.worldGroup = new THREE.Group()
        this.scene.add(this.worldGroup)

        this.scene.fog = new THREE.Fog(0x000000, -5, 0)

        // Create 3D world objects and add them to the world group
        this.ground = new Ground()
        this.ground.instance.rotation.set(Math.PI / 2, 0, 0)
        this.ground.instance.position.set(0, -1.5, 0)
        this.ground.instance.material.opacity = 0

        this.worldGroup.add(this.ground.instance)

        if (this.debug.active) {
            const axis = new THREE.AxesHelper(5)
            this.scene.add(axis)
        }
        
        const defaultWeight = '37'

        this.knife = new Knife(defaultWeight)
        this.knife.fadeOut(0)
        this.knife.on('closed', this.onKnifeClosedBound)
        this.knife.on('opened', this.onKnifeOpenedBound)
        this.worldGroup.add(this.knife.instance)

        this.ui.setMenusWithWeight(defaultWeight)

        const tl = gsap.timeline({})
        tl.to(this.scene.fog, {near: 10, ease: "sine.inOut", duration: 5})
        tl.to(this.scene.fog, {far: 15, ease: "sine.inOut", duration: 5}, '<')
        tl.to(this.ground.instance.material, {opacity: 1.0, ease: "power4.in", duration: 2}, '<')
    }

    initUI() {
        this.ui = new UIManager(document.querySelector('.ui'))
        this.ui.on('openKnife', this.openKnifeBound)
        this.ui.on('closeKnife', this.closeKnifeBound)
        this.ui.on('changeKnifeFinish', this.changeKnifeFinishBound)
        this.ui.on('changeKnifeWeight', this.changeKnifeWeightBound)
    }

    changeKnifeFinish(data) {
        this.knife.changeFinish(data.finish)
        this.state.knifeFinish = data.finish
    }

    changeKnifeWeight(data) {
        this.knife.destroy()
        this.worldGroup.remove(this.knife.instance)
        this.ui.toggleMenus()
        this.ui.hideOpenCloseButtons()
        
        const weight = parseInt(data.weight)
        this.ui.setMenusWithWeight(weight)
        this.state.knifeWeight = weight

        if (weight === 15) {
            this.knife = new Knife('15')
        }
        else if (weight === 27) {
            this.knife = new Knife('27')
        }
        else {
            this.knife = new Knife('37')
        }
        
        this.knife.on('closed', this.onKnifeClosedBound)
        this.knife.on('opened', this.onKnifeOpenedBound)
        this.worldGroup.add(this.knife.instance)
    }

    openKnife() {
        if (!this.knife.closed) {
            return
        }

        this.knife.open()
    }
      
    closeKnife() {
        if (this.knife.closed) {
            return
        }
        
        this.ui.toggleMenus()
        this.knife.close()
    }
    
    onKnifeOpened(e) {
        this.ui.toggleOpenCloseButtons(true)
        this.ui.toggleMenus()
    }
    
    onKnifeClosed(e) {
        this.ui.toggleOpenCloseButtons(false)
    }

    update() {
        this.camera.update()

        this.ui.update()

        // Camera rotation mode
        // Display the world with camera instance controlled by OrbitControls
        this.renderer.render(this.scene, this.camera.instance)

        // Object rotation mode
        // Don't use the camera instance. Instead we will move the world group with transposed rotation
        // Render with the fake camera not moving, but supporting the dolly in and out (zoom)
        // this.worldGroup.quaternion.copy(this.camera.instance.quaternion.clone().conjugate())
        // this.renderer.render(this.scene, this.camera.fake)
    }
    
    destroy() {
        this.knife.off('closed')
        this.knife.off('opened')

        this.ui.off('openKnife')
        this.ui.off('closeKnife')
        this.ui.off('changeKnifeFinish')
        this.ui.off('changeKnifeWeight')

        this.assetManagerReadyHandlerBound = null

        this.closeKnifeBound = null
        this.openKnifeBound = null
        this.changeKnifeFinishBound = null
        this.changeKnifeWeightBound = null

        this.onKnifeOpenedBound = null
        this.onKnifeClosedBound = null


        this.lights.destroy()
        this.lights = null

        this.knife.destroy()
        this.knife = null

        this.ground.destroy()
        this.ground = null

        Memory.clearScene(this.worldGroup)
        this.worldGroup = null

        // this.gamepad.destroy()
        // this.gamepad = null

        this.assetManagerReadyHandlerBound = null
    }
}