import { AmbientLight, CameraHelper, DirectionalLight, DirectionalLightHelper, Group, HemisphereLight, PointLight, SpotLight, SpotLightHelper } from "three"
import { Memory } from "../Utils/Memory"
import { App } from "../App"
import { EventEmitter } from "../Utils/EventEmitter"

const CONFIG = {
    env: 1,
    ambient: 4, 
    point: 50, 
    sun: 5,
    spot: 400.0
}

export class Lights extends EventEmitter {
    constructor() {
        super()

        this.app = new App()

        this.instance = new Group()
        
        this.init()
    }
    
    init() {
        this.app.scene.environment = this.app.assetManager.items.envmap
        this.app.scene.environmentRotation.set(0, -Math.PI / 2, 0)
        this.app.scene.environmentIntensity = CONFIG.env

        const ambientLight = new AmbientLight(0xf0f0f0, 1)
        ambientLight.name = "ambient"
        ambientLight.color.convertLinearToSRGB()
        ambientLight.intensity = CONFIG[ambientLight.name]
        this.instance.add(ambientLight)
        
        const dirLight = new DirectionalLight(0xd9b0af, 1.2)
        dirLight.name = "sun"
        dirLight.color.convertLinearToSRGB()
        dirLight.intensity = CONFIG[dirLight.name]
        dirLight.position.set(0, 3, -2)
        dirLight.castShadow = true
        this.instance.add(dirLight)

        // const pointLight = new PointLight(0xf0f0d0, CONFIG.point)
        // pointLight.name = "point"
        // pointLight.position.set(4, 1.2, -5)
        // this.instance.add(pointLight)
        
        const spotLight = new SpotLight(0xd9b07f, 400, 0, Math.PI/4, 0.6)
        spotLight.name = "spot"
        spotLight.color.convertLinearToSRGB()
        spotLight.position.set(3, 3, -3)
        spotLight.target.position.set(3, 0, -3)
        // spotLight.castShadow = true
        this.instance.add(spotLight)

        // const spotLightHelper = new SpotLightHelper( spotLight );
        // this.app.scene.add( spotLightHelper );
        
        // spotLight.shadow.mapSize.width = 1024
        // spotLight.shadow.mapSize.height = 1024

        // spotLight.shadow.camera.near = 0.01
        // spotLight.shadow.camera.far = 40
        // spotLight.shadow.camera.fov = 20

        // const camH = new CameraHelper(spotLight.shadow.camera)
        // this.instance.add(camH)

        
        if (this.app.debug.active) {
            const lf = this.app.debug.ui.addFolder("Lights")

            lf.add(this.app.scene, "environmentIntensity", 0, 1, 0.01).name("envmap").onChange(() => {
                this.trigger('envmapChanged')
            })

            // lf.add(pointLight, "intensity", 0, 100, 0.5).name("point")
            // lf.add(pointLight.position, "y", 0, 2, 0.01).name("point height")


            // lf.add(hemiLight, "intensity", 0, 5, 0.1).name("sun")
            // lf.addColor(hemiLight, "skyColor").name("sky")
            // lf.addColor(hemiLight, "groundColor").name("ground")

            
            lf.add(ambientLight, "intensity", 0, 50, 0.1).name("ambient")
            lf.addColor(ambientLight, "color").name("ambient color")

            
            // lf.add(dirLight, "intensity", 0, 5, 0.1).name("dir")
            // lf.addColor(dirLight, "color").name("dir color")
            
            // const dirLightH = new DirectionalLightHelper(dirLight)
            // dirLightH.visible = false
            // dirLight.add(dirLightH)
            // lf.add(dirLightH, "visible", false).name("dir helper")
            
            lf.add(spotLight, "intensity", 0, 500, 1).name("spot")
            lf.addColor(spotLight, "color").name("spot color")
            
            const spotLightH = new SpotLightHelper(spotLight)
            spotLightH.visible = false
            spotLight.add(spotLightH)
            lf.add(spotLightH, "visible", false).name("spot helper")
        }
    }
    
    destroy() {
        Memory.clearScene(this.instance)

        this.instance = null
    }
}