import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'

import { App } from '/App'
import { EventEmitter } from '/Utils/EventEmitter'

/*
 In fixed mode, the camera is fixed and the scene is rotating
 */

export class Camera extends EventEmitter {
    constructor(controllable) {
        super()

        this.controllable = typeof controllable === "boolean" ? controllable : true

        this.app = null
        this.instance = null
        this.fake = null
        this.controls = null

        this.resizeHandlerBound = this.resizeHandler.bind(this)
        this.updateBound = this.update.bind(this)

        this.init()
    }

    init() {
        this.app = new App()

        this.instance = new THREE.PerspectiveCamera(45, this.app.renderSize.aspect, 0.01, 100)
        this.fake = this.instance.clone()
        
        if (this.controllable) {
            // this.controls = new GamepadOrbitControls(this.instance, this.app.canvas)
            this.controls = new OrbitControls(this.instance, this.app.canvas)
            this.controls.enablePan = false
            this.controls.enableDamping = true
            this.controls.dampingFactor = 0.03

            if (this.app.debug.active) {
                const cf = this.app.debug.ui.addFolder("Camera")
                cf.add(this.controls, "enableDamping", true)
                cf.add(this.controls, "dampingFactor", 0, 0.2, 0.01)
                cf.add(this.controls, "autoRotate", false).name("Auto rotation")
            }
        }
        
        // this.app.animationLoop.on('update', this.updateBound)
        this.app.renderSize.on('resize', this.resizeHandlerBound)
    }


    setPositionZ(z) {
        this.instance.position.z = this.fake.position.z = z
    }

    setLimits(minDistance, maxDistance, minPolarAngle, maxPolarAngle) {
        this.controls.minDistance = minDistance
        this.controls.maxDistance = maxDistance

        this.controls.minPolarAngle = minPolarAngle
        this.controls.maxPolarAngle = maxPolarAngle
    }

    update() {
        const sPos = new THREE.Spherical().setFromVector3(this.instance.position)
        this.fake.position.z = sPos.radius

        if (this.controls) {
            this.controls.update()
        }
    }

    resizeHandler(info) {
        this.instance.aspect = info.aspect
        this.instance.updateProjectionMatrix()
        
        this.fake.aspect = info.aspect
        this.fake.updateProjectionMatrix()

        this.fovWidth = this.fovHeight * info.width / info.height
    }

    destroy() {
        this.app.animationLoop.off('resize')
        this.updateBound = null

        this.app.renderSize.off('resize')
        this.resizeHandlerBound = null

        this.fake = null
        this.instance = null

        this.app = null
    }
}