export default class Zoom {
    constructor(className) {
        this.element = document.querySelector('.' + className)
        
        this.init()
    }
    
    init() {
        const style = {
        }        
    }
    
    destroy() {
        this.element = null
    }
}
