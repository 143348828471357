export default [
    // envmap
    {
        name: 'envmap',
        type: 'HDR',
        path: 'envmap/envmap.hdr',
        license: '',
        author: '',
        url: ''
    },

    // Models
    {
        name: 'knife-15',
        type: 'GLTF',
        path: 'models/model-15G.glb',
        license: '',
        author: '',
    },

    {
        name: 'knife-27',
        type: 'GLTF',
        path: 'models/model-27G.glb',
        license: '',
        author: '',
    },

    {
        name: 'knife-37',
        type: 'GLTF',
        path: 'models/model-37G.glb',
        license: '',
        author: '',
    },
    
    // Background textures
    {
        name: 'restaurant',
        type: 'texture',
        envmap: true,
        path: 'background/restaurant.jpg',
        license: '',
        author: '',
    },
                
    // Tattoo textures
    {
        name: 'press',
        type: 'texture',
        path: 'textures/press.png',
        license: '',
        author: '',
    },

    {
        name: 'arbre-27-light',
        type: 'texture',
        path: 'tattoo/13527_1600-arbre.png',
        license: '',
        author: '',
    },

    {
        name: 'arbre-27-dark',
        type: 'texture',
        path: 'tattoo/13889_1600-arbre.png',
        // path: 'tattoo/13659_1600-art-deco.png',
        license: '',
        author: '',
    },

    // Materials
    {
        name: 'ground-wood',
        type: 'material',
        path: 'materials/ground-wood',
        url: '',
        textures: {
            map: 'map.jpg',
            normal: 'normal.jpg',
            alpha: 'alpha.png'
        }
    },

    {
        name: 'blade',
        type: 'material',
        path: 'materials/blade',
        url: '',
        textures: {
            orm: 'ao_metal_rough.jpg'
        }
    },

    {
        name: 'olive',
        type: 'material',
        path: 'materials/olive',
        url: '',
        textures: {
            map: 'olive.jpg',
            normal: 'olive_normal.jpg'
        }
    }
]
