import { MathUtils, Group, BufferAttribute, MeshStandardMaterial, MeshNormalMaterial, Color, AdditiveBlending } from "three"
import { App } from "../App"
import { Memory } from "../Utils/Memory"
import { EventEmitter } from "../Utils/EventEmitter"
import gsap from "gsap"

import ModelUtils from "../Utils/ModelUtils"
import { ColorRGB } from "../Utils/Utils"

const KNIFE_METAL = {
    mirror: {
        color: new ColorRGB("#ffffff"),
        roughness: 0.3,
        metalness: 0.8
    },
    titanium: {
        color: new ColorRGB("#ffffff"),
        roughness: 0.73,
        metalness: 0.8
    },
    black: {
        color: new ColorRGB("#2a2a2a"),
        roughness: 0.73,
        metalness: 0.8
    }
}

const BLADE_TATTOO = {
    dark: {
        color: new ColorRGB("#f0f0f0")
    },
    light: {
        color: new ColorRGB("#0d0d0d")
    }
}

const SIZE_DEPENDANT = {
    positionY : {
        "15": {
            opened: -0.3,
            closed: 1.1
        },
        "27": {
            opened: 0,
            closed: 1.4 
        },
        "37":  {
            opened: 0.5,
            closed: 1.9 
        },
    }
}

const PARTS = {
    handle: ['handle', 'handle_body', 'handle_body_tattoo', 'front_screw_main', 'front_screw_0', 'front_screw_1', 'front_screw_2', 'front_lock', 'back_screw_0', 'back_screw_1', 'back_screw_main', 'back_clipper', 'text_mesh'],
    blade: ['blade', 'blade_edge', 'blade_tattoo'],
    center: ['disc_front', 'disc_back', 'sphere_lock']
}

export default class Knife extends EventEmitter {
    constructor(type) {
        super()

        this.type = typeof type === "string" ? type : "27"

        this.app = new App()
        this.ui = null

        this.loadedModel = null
        this.instance = null
        this.closed = true

        this.blade = null
        this.handle = null

        this.closed = true

        this.materials = []

        this.init()
    }
    
    init() {
        // Behavior constants
        this.handleOpenedAngle = 0
        this.handleClosedAngle = MathUtils.degToRad(44)
        
        this.bladeOpenedAngle = 0
        this.bladeClosedAngle = MathUtils.degToRad(-128)
        
        this.openY = SIZE_DEPENDANT.positionY[this.type].opened
        this.closeY = SIZE_DEPENDANT.positionY[this.type].closed
        
        // Model creation
        const model = this.app.assetManager.getItem('knife-' + this.type)
        this.loadedModel = model

        this.instance = model.scene
        this.instance.scale.set(0.1, 0.1, 0.1)
        this.instance.rotation.x = -Math.PI / 2
        this.instance.scale.set(0.075, 0.075, 0.075)

        this.blade = new Group()
        this.blade.name = "blade"

        this.handle = new Group()
        this.handle.name = "handle"
        
        this.center = new Group()
        this.center.name = "center"

        this.instance.add(this.blade, this.handle, this.center)
        
        this.nodes = ModelUtils.useNodes(this.instance)

        for (const n in this.nodes) {
            this.nodes[n].castShadow = true
            this.nodes[n].receiveShadow = true
        }

        if (this.app.debug.active) {
            this.ui = this.app.debug.ui.addFolder('Knife')

            const params = {
                open : this.open.bind(this),
                close : this.close.bind(this)
            }

            this.ui.add(params, 'open')
            this.ui.add(params, 'close')
        }

        this.setupMaterials()
        this.setupNodes()
        this.setupGroups()
        this.setupHandleTattoo()
        
        // Init position and rotation for each part
        this.instance.rotation.y = Math.PI / 8
        this.handle.rotation.y = this.handleClosedAngle
        this.blade.rotation.y = this.bladeClosedAngle
        this.instance.position.y = this.closeY

        this.changeBladeTattoo("arbre-27-light")

        this.open()
    }

    setupNodes() {

        if (this.nodes.handle) {
            this.nodes.handle.material = this.handleMaterial
        }

        if (this.nodes.handle_body) {
            this.nodes.handle_body.material = this.metalMaterial
            this.nodes.handle_body.geometry.setAttribute('uv2', this.nodes.blade.geometry.getAttribute('uv').clone())
        }
        
        if (this.nodes.handle_body_tattoo) {
            this.nodes.handle_body_tattoo.material = this.metalMaterial
            this.nodes.handle_body_tattoo.geometry.setAttribute('uv2', this.nodes.blade.geometry.getAttribute('uv').clone())
        }
        
        if (this.nodes.text_mesh) {
            this.nodes.text_mesh.material = this.metalMaterial
            this.nodes.text_mesh.geometry.setAttribute('uv2', this.nodes.blade.geometry.getAttribute('uv').clone())
        }
        
        if (this.nodes.blade) {
            this.nodes.blade.material = this.metalMaterial
            this.nodes.blade.geometry.setAttribute('uv2', this.nodes.blade.geometry.getAttribute('uv').clone())
        }
        
        if (this.nodes.blade_edge) {
            this.nodes.blade_edge.material = this.bladeMaterial
        }
        
        if (this.nodes.blade_tattoo) {
            this.nodes.blade_tattoo.material = this.bladeTattooMaterial
        }
        
        if (this.nodes.disc_back) {
            this.nodes.disc_back.material = this.metalMaterial
            this.nodes.disc_back.geometry.setAttribute('uv2', this.nodes.blade.geometry.getAttribute('uv').clone())
        }
        
        if (this.nodes.disc_front) {
            this.nodes.disc_front.material = this.metalMaterial
            this.nodes.disc_front.geometry.setAttribute('uv2', this.nodes.blade.geometry.getAttribute('uv').clone())
        }
        
        if (this.nodes.back_clipper) {
            this.nodes.back_clipper.material = this.metalMaterial
            this.nodes.back_clipper.geometry.setAttribute('uv2', this.nodes.blade.geometry.getAttribute('uv').clone())
        }
        
        if (this.nodes.sphere_lock) {
            this.nodes.sphere_lock.material = this.metalMaterial
            this.nodes.sphere_lock.geometry.setAttribute('uv2', this.nodes.blade.geometry.getAttribute('uv').clone())
        }
        
        if (this.nodes.front_screw_main) {
            this.nodes.front_screw_main.material = this.metalMaterial
            this.nodes.front_screw_main.geometry.setAttribute('uv2', this.nodes.blade.geometry.getAttribute('uv').clone())
        }
        
        if (this.nodes.back_screw_0) {
            this.nodes.back_screw_0.material = this.metalMaterial
            this.nodes.back_screw_0.geometry.setAttribute('uv2', this.nodes.blade.geometry.getAttribute('uv').clone())
        }
        
        if (this.nodes.back_screw_1) {
            this.nodes.back_screw_1.material = this.metalMaterial
            this.nodes.back_screw_1.geometry.setAttribute('uv2', this.nodes.blade.geometry.getAttribute('uv').clone())
        }
        
        if (this.nodes.back_screw_main) {
            this.nodes.back_screw_main.material = this.metalMaterial
            this.nodes.back_screw_main.geometry.setAttribute('uv2', this.nodes.blade.geometry.getAttribute('uv').clone())
        }
        
        if (this.nodes.front_lock) {
            this.nodes.front_lock.material = this.metalMaterial
            this.nodes.front_lock.geometry.setAttribute('uv2', this.nodes.blade.geometry.getAttribute('uv').clone())
        }
        
        if (this.nodes.front_screw_0) {
            this.nodes.front_screw_0.material = this.metalMaterial
            this.nodes.front_screw_0.geometry.setAttribute('uv2', this.nodes.blade.geometry.getAttribute('uv').clone())
        }
        
        if (this.nodes.front_screw_1) {
            this.nodes.front_screw_1.material = this.metalMaterial
            this.nodes.front_screw_1.geometry.setAttribute('uv2', this.nodes.blade.geometry.getAttribute('uv').clone())
        }
        
        if (this.nodes.front_screw_2) {
            this.nodes.front_screw_2.material = this.metalMaterial
            this.nodes.front_screw_2.geometry.setAttribute('uv2', this.nodes.blade.geometry.getAttribute('uv').clone())
        }
    }

    setupGroups() {
        const isPartPresent = (part) => { return typeof this.nodes[part] !== "undefined" }
        const getPartNode = (partName) => { return this.nodes[partName] }

        this.handle.add(...PARTS.handle.filter(isPartPresent).map(getPartNode))
        this.blade.add(...PARTS.blade.filter(isPartPresent).map(getPartNode))
        this.center.add(...PARTS.center.filter(isPartPresent).map(getPartNode))
    }

    setupMaterials() {
        // Dispose common material first time the model is loaded, and assign right material to each part
        if (this.nodes.blade.material) {
            this.nodes.blade.material.dispose()
            this.nodes.blade.material = null
        }

        // Create new materials for each part
        this.handleMaterial = new MeshStandardMaterial({
            color: new ColorRGB("#ffffff"),
            // transparent: true,
            // opacity: 1,
            roughness: 0.75,
            metalness: 0.95,
            map: this.app.assetManager.items['olive'].map,
            normalMap: this.app.assetManager.items['olive'].normal
        })
        
        this.metalMaterial = new MeshStandardMaterial({
            color: new ColorRGB("#ffffff"),
            // transparent: true,
            // opacity: 1,
            roughness: 0.3,
            metalness: 0.9,
            roughnessMap: this.app.assetManager.items['blade'].orm,
            aoMap: this.app.assetManager.items['blade'].orm
        })

        this.bladeMaterial = this.metalMaterial.clone()

        this.bladeTattooMaterial = new MeshStandardMaterial({
            color: new ColorRGB("#0d0d0d"),
            transparent: true,
            opacity: 1
        })

        this.handleTattooMaterial = new MeshStandardMaterial({
            color: new ColorRGB("#0d0d0d"),
            transparent: true,
            opacity: 1
        })

        this.materials.push(this.handleMaterial, this.metalMaterial, this.bladeMaterial, this.bladeTattooMaterial, this.handleTattooMaterial)

        if (this.ui) {
            const mf = this.ui.addFolder("Metal")
            mf.addColor(this.metalMaterial, 'color').listen()
            mf.add(this.metalMaterial, 'roughness', 0, 1, 0.01).listen()
            mf.add(this.metalMaterial, 'metalness', 0, 1, 0.01).listen()

            const tf = this.ui.addFolder("Blade tattoo")
            tf.addColor(this.bladeTattooMaterial, 'color').listen()
        }
    }

    fadeIn(duration) {
        duration = typeof duration === "number" ? duration : 1
        const tl = gsap.timeline({})
        
        this.materials.forEach(material => {
            tl.to(material, {opacity: 1, ease: "power4.out", duration: duration}, '<')
        })
    }

    fadeOut(duration) {
        duration = typeof duration === "number" ? duration : 1
        const tl = gsap.timeline({})
        
        this.materials.forEach(material => {
            tl.to(material, {opacity: 0, ease: "power4.out", duration: duration}, '<')
        })
    }

    setupHandleTattoo(tattoo) {
        // this.handleTattooMaterial.map = this.app.assetManager.items["arbre"]
    }
    
    changeBladeTattoo(tattoo) {
        this.bladeTattooMaterial.map = this.app.assetManager.items[tattoo]
        this.bladeTattooMaterial.opacity = 0
        gsap.timeline().to(this.bladeTattooMaterial, {opacity: 1.0, ease: "power4.in", duration: 3})        
    }

    open() {
        if (!this.closed) {
            return
        }

        const tl = gsap.timeline({
            onComplete : () => {
                this.closed = false
                this.trigger('opened')

            }
        })
        
        tl.to(this.handle.rotation, {y: this.handleOpenedAngle, ease: "power4.out", duration: 3})
        tl.to(this.blade.rotation, {y: this.bladeOpenedAngle, ease: "power4.out", duration: 3}, '<')
        tl.to(this.instance.position, {y: this.openY, ease: "power4.out", duration: 3}, '<')
    }
    
    close() {
        if (this.closed) {
            return
        }
        
        const tl = gsap.timeline({
            onComplete : () => {
                this.closed = true
                this.trigger('closed')
            }
        })

        tl.to(this.handle.rotation, {y: this.handleClosedAngle, ease: "power4.out", duration: 3})
        tl.to(this.blade.rotation,  {y: this.bladeClosedAngle, ease: "power4.out", duration: 3}, '<')
        tl.to(this.instance.position, {y: this.closeY, ease: "power4.out", duration: 3}, '<')
    }

    changeFinish(finish) {
        finish = finish.toLowerCase()
        this.metalMaterial.roughness = KNIFE_METAL[finish].roughness
        this.metalMaterial.metalness = KNIFE_METAL[finish].metalness
        this.metalMaterial.color = KNIFE_METAL[finish].color
        
        const suffix = (finish === 'black') ? 'dark' : 'light'
        this.bladeTattooMaterial.color = BLADE_TATTOO[suffix]['color']
        this.bladeTattooMaterial.map = this.app.assetManager.items["arbre-27-" + suffix]
    }

    changeWeight(weight) {
        console.log("Knife change weight : " + weight)
    }
            
    destroy() {
        Memory.releaseObject3DMaterials(this.instance)
        
        this.nodes.length = 0
        this.nodes = null

        this.materials.length = 0
        this.materials = null

        if (this.ui !== null) {
            this.ui = null
        }
    }
}