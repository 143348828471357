import { Vector3 } from "three"
import { EventEmitter } from "../../Utils/EventEmitter"
import { Utils } from "../../Utils/Utils"

export default class FinishMenu extends EventEmitter {
    constructor(manager, className, position, visible) {
        super()

        this.manager = manager
        this.instance = document.querySelector('.' + className)
        this.instance.style.opacity = typeof visible === 'boolean' ? (visible ? 1 : 0) : 1

        this.name = className
        this.width = 0
        this.worldPt = position ? Utils.Vector2FromVector3(position) : new Vector3()

        this.onClickBound = null
        
        this.init()
    }
    
    init() {
        this.items = Array.from(this.instance.querySelectorAll('li'))

        this.onClickBound = this.onClick.bind(this)
        this.instance.addEventListener('mouseup', this.onClickBound)

        this.width = parseInt(window.getComputedStyle(this.instance).width)

        this.applyTransform()
    }

    applyTransform() {
        const screenPt = this.manager.worldToScreenPt(this.worldPt)
        this.instance.style.transform = `translateX(${screenPt.x - this.width * 0.5}px) translateY(${screenPt.y}px)`
    }

    onClick(e) {
        const li = e.target
        const finish = li.dataset.finish
        this.manager.changeKnifeFinish(finish)

        this.items.map(i => i.classList.remove('selected'))
        li.classList.add('selected')
    }
    
    update() {
        this.applyTransform()
    }
    
    destroy() {
        this.instance.removeEventListener('mouseup', this.onClickBound)
        this.onClickBound = null

        this.items.length = 0
        this.items = null

        this.worldPt = null
        this.instance = null
        this.manager = null
    }
}
